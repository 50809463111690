import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OrderDetails from "../order/orderdetails";
import {
  getSingleIngredients,
  loadDescription,
  SetCartData as SetCartDataAction
} from "../../store/actions/menuAction";
import { Sync_Carts } from "../../store/actions/orderAction";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Navigation, Pagination, Mousewheel, Keyboard, EffectCoverflow} from 'swiper/modules';
import {compareDesc, parse} from "date-fns";
import axios from "axios";
import {absoluteUrl} from "../../common/Constants";

import {useQuery} from '@tanstack/react-query';

function compareDate(firstDate, secondDate) {
    if(compareDesc(new Date(firstDate), new Date(secondDate)) === -1) return false
    else return true
}

async function getAllGroups(busId) {
    try {
        const allgroups = await axios.get(absoluteUrl(`/group-logo/${busId}`));
        return allgroups.data.data.filter(function filterVisibleGroups(group) {
            const startTime = parse(group.startTime, "HH:mm", new Date());
            const endTime = parse(group.endTime, "HH:mm", new Date());
            if (typeof group.visible === 'undefined' || group.visible === null) {
                return true;
            }
            if (!group.active && group.visible) return true;
            if (group.active && group.visible && compareDate(new Date(), endTime) && compareDate(startTime, new Date()))
                return true;
            return false;
        });
   
    } catch (error) {
        console.error("ERROR_FETCHING_GROUPS", error);
    }
}


export const SearchResults = ({ groups, swiping, setShowCart, setInfoAllergens, setAllergenData, page_theme, searchSectionRef }) => {
  const dispatch = useDispatch();
  const searchResponse = useSelector((state) => state.menu.searchResults);
  const busId = useSelector((state) => state?.table?.orderInfo?.businessLocationId);

  const [searchData, setSearchData] = useState([]);
  const [filterDone, setFilterDone] = useState(false);
  const testImg = ["./img/PlatenKnife.png"];
  const { data: allGroups } = useQuery({
    queryKey: ['allgroups', busId],
    queryFn: () => getAllGroups(busId)
});
var visibleGroups = new Set();
  const sortSearchResults = (groups, searchData) => {
    let array1 = [];
    let array2 = [];
    let length = searchData[0].categories.length;
    groups.map((groupName) => {
      searchData.forEach((item) => {
        if (item.group === groupName) {
          if (item.categories.length === length) {
            array1.push(item);
          } else {
            array2.push(item);
          }
        }
      });
    });
    setSearchData([...array1, ...array2]);

    for(var g=0;g<allGroups?.length;g++){
      var groupss = allGroups[g];
      visibleGroups.add(groupss?.group);
      var tableauDeGroupes2 = Array.from(visibleGroups);
    }
   const visibleElements = searchData.filter(element => tableauDeGroupes2.includes(element.group));
   setSearchData(visibleElements);
   setFilterDone(true)
    
  };

  useEffect(() => {
    if (searchResponse.length) {
      console.log(filterDone);
      sortSearchResults(groups, searchResponse);
    }
  }, [searchResponse]);


  return (
      <>
        <Row className="w-100 gx-0 gy-0" ref={searchSectionRef}>
          {searchData?.length > 0 && (
              <Col md="12" sm="12" lg="12" xs="12">
                <div className="sectionHdng">
                  <div className="hdngflex">
                    <h1>SUCHERGEBNIS</h1>
                  </div>
                  <div className="hdnglineblue"></div>
                </div>
              </Col>
          )}
          <Col md="12" lg="12" sm="12" xs="12" style={{  position: 'relative'}}>
            {searchData?.length > 0 && (
                <Swiper
                    cssMode={true}
                    navigation={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard,EffectCoverflow ]}
                    className="mySwiper"
                    slidesPerView={2.5}
                    spaceBetween={0}
                    coverflowEffect={{
                      rotate: 0,
                      stretch: 0,
                      depth: 0,
                    }}
                >
                  {searchData?.map((data) => {
                    return (
                        <SwiperSlide key={uuidv4()}
                                     id={data?.id}
                                     style={{
                                       cursor: "pointer"
                                     }}
                                     className="dish-wrapper">
                          <h1 className="menu-itemName d-flex justify-content-center mb-2  mb-2 ps-1 pe-1 fs-6">
                            {data?.name}
                          </h1>
                          <div className="p-1 dish-img-container">
                            <img
                                style={{ cursor: "pointer" }}
                                src={data?.image || testImg[0]}
                                className=" dish-img1"
                                alt="menu-item"
                                onClick={() => {
                                  if (!swiping) {
                                    dispatch(SetCartDataAction(data));
                                    dispatch(loadDescription({ sku: data?.sku, blId: busId }));
                                    dispatch(Sync_Carts(data));
                                    setShowCart(true);
                                    dispatch(
                                        getSingleIngredients({
                                          sku: data.sku,
                                          blId: busId
                                        })
                                    );
                                  }
                                }}
                            />

                            {data.isBestSeller === true ? (
                                <img
                                    src="/img/hearticon.png"
                                    className="heartImg"
                                    alt="hearticon"
                                    onClick={() => {
                                      setInfoAllergens(true);
                                    }}
                                />
                            ) : (
                                <img
                                    src="/img/hearticon.png"
                                    className="heartImg invisible"
                                    alt="hearticon"
                                />
                            )}
                          </div>
                          <OrderDetails
                              page_theme={page_theme}
                              data={data}
                              setInfoAllergens={setInfoAllergens}
                              setAllergenData={setAllergenData}
                          />
                        </SwiperSlide>
                    );
                  })}

                </Swiper>
            )}
          </Col>
        </Row>
      </>
  );
};
