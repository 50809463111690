import {
  CHANGE_ING_STATUS,
  GET_ALLERGEN,
  GET_MENU,
  SET_CART_DATA,
  SET_INGREDIENTS,
  SET_SEARCH_RESULTS,
  SET_CART_INGREDIENTS,
  SET_VEGAN_ITEMS,
  SET_TAKEAWAY,
  GET_SINGLE_INGREDIENTS
  // SET_RELATED_PRODS
} from "../types";
import axios from "axios";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

let descriptionRepository = RepositoryFactory.get("getDescription");
let ingredientRepository = RepositoryFactory.get("getIngredients");

export const menuAction = (payload) => async (dispatch) => {
  try {
    // let { data } = await axios.get(
    //   `${process.env.REACT_APP_BackendURL}/menus/menus/${payload}?isCache=false`
    // );
    let { data } = await axios.get(
      `${process.env.REACT_APP_BackendURL}/menu_v2/${payload}`
    );
    if (data?.data) {
      data?.data?.sort((a, b) => {
        if (!b.position) {
          return -1;
        }
        return a.position - b.position;
      });
    }
    dispatch({
      type: GET_MENU,
      payload: data?.data
    });
  } catch (err) {
    alert("Something went wrong (menuAction 30) try again " + err);
  }
};

export const getTakeAwayMenu = (payload) => async (dispatch) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_BackendURL}/menus/take-aways/${payload}`);

    dispatch({
      type: GET_MENU,
      payload: res?.data.data
    });
  } catch (err) {
    alert("Something went wrong (menu action 40) try again" + err);
  }
};

export const ChangeTakeawayStatus = (payload) => {
  return {
    type: SET_TAKEAWAY,
    payload
  };
};

export const resetMenu = () => async (dispatch) => {
  dispatch({
    type: "RESET_MENU"
  });
};

export const selectIngGroup = (payload) => async (dispatch) => {
  dispatch({
    type: "SELECT_ING_GROUP",
    payload: payload
  });
};

export const loadDescription = (payload) => async (dispatch) => {
  try {
    const res = await descriptionRepository.getDescription(payload);
    dispatch({
      type: "GET_DESCRIPTION",
      payload: res?.data
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const getAllergens = () => async (dispatch) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_BackendURL}/allergen`);

    dispatch({
      type: GET_ALLERGEN,
      payload: res?.data.data
    });
  } catch (err) {
    alert("Something went wrong (menuAction 92) try again" + err);
  }
};

export const ChangeIngStatus = (payload) => {
  return {
    type: CHANGE_ING_STATUS,
    payload
  };
};

export const SetSearchResults = (payload) => {
  return {
    type: SET_SEARCH_RESULTS,
    payload
  };
};

export const SetCartData = (payload) => {
  return {
    type: SET_CART_DATA,
    payload
  };
};

export const getSingleIngredients = (payload) => async (dispatch) => {
  try {
    const res = await ingredientRepository.getIngredients(payload);
    dispatch({
      type: GET_SINGLE_INGREDIENTS,
      payload: res?.data?.data?.ingredients
    });
    // dispatch({
    //   type: SET_RELATED_PRODS,
    //   payload: res?.data?.data?.related_products
    // });

    let updatedIncridients = res?.data?.data?.ingredients.map((item) => {
      if (item.type === "Standard") {
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    let incObj = {
      ingredients: updatedIncridients,
      mandatory: res?.data?.data?.mandatory,
      single_only: res?.data?.data?.single_only
    };
    dispatch(SetIngredients(incObj));
  } catch (error) {
    console.log("error", error);
  }
};

export const SetIngredients = (payload) => {
  return {
    type: SET_INGREDIENTS,
    payload
  };
};

export const SetCartIngredients = (payload) => {
  return {
    type: SET_CART_INGREDIENTS,
    payload
  };
};
var veganItemsRepository = RepositoryFactory.get("getvegan");
export const loadVeganItems = (payload) => async (dispatch) => {
  try {
    const res = await veganItemsRepository.getVeganItems(payload);
    dispatch({
      type: SET_VEGAN_ITEMS,
      payload: res.data
    });
  } catch (error) {
    console.log("error", error);
  }
};
