import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Row } from "react-bootstrap";

const OrderDetails = ({ data, page_theme, setInfoAllergens, setAllergenData }) => {
  return (
      <>
        <Row noGutters  className="gx-0 ps-1">
          <Col md="12" lg="12" sm="12" xs="12">
            <Row noGutters className="gx-0 position-relative">
              <div className="price-info-container">
                {data?.price && (
                    <b
                        className="d-flex justify-content-center justify-content-lg-end justify-content-md-center pt-1"
                        style={{
                          fontSize: page_theme?.font_size_menu_item_price,
                          fontFamily: page_theme?.font_family_menu_item_price,
                          fontWeight: "600",
                          color: page_theme?.primary_menu_item_price_color,
                          paddingBottom: "10px"
                        }}>
                      € {data?.price}
                    </b>
                )}
                <div className="info-img" role="button">
                  <img
                      src="/img/info.png"
                      className={
                        data?.allergens?.length > 0
                            ? "mt-1 cate-icons visible "
                            : "mt-1 cate-icons invisible"
                      }
                      height={18}
                      alt="cover"
                      onClick={() => {
                        setInfoAllergens(true);
                        setAllergenData(data?.allergens);
                      }}
                  />
                </div>
              </div>
              <Col md="12" lg="6" sm="12" xs="12">
                <div className="d-flex justify-content-center justify-content-lg-end justify-content-md-center algin-item-center mt-0 mt-md-1 pt-lg-1">
                  {data?.categories?.map((cat) => {
                    return (
                        <React.Fragment key={uuidv4()}>
                          {cat?.icon !== null ? (
                              <img
                                  key={uuidv4()}
                                  role="button"
                                  src={cat?.icon}
                                  className=" mx-1 cate-icons"
                                  height={18}
                                  alt="cover"
                                  style={{
                                    cursor: "pointer"
                                  }}
                              />
                          ) : (
                              ""
                          )}
                        </React.Fragment>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
  );
};
export default OrderDetails;
