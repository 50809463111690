import React from "react";
import encBase64 from "crypto-js/enc-base64";
import hmacSha256 from "crypto-js/hmac-sha256";
import { SetLoading } from "../../store/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";

const CreditModal = (props) => {
  const dispatch = useDispatch();

  const paymentInfo = useSelector((state) => state.order.orderPaymentInfo);
  const loadingStatus = useSelector((state) => state.order.loading);

  const handlePayment = () => {
    dispatch(SetLoading(true));
    // if (paymentStatus) {
    paymentCreditConfig();
    // } else {
    // setTimeout(() => {
    //   paymentCreditConfig();
    // }, 4000);
    // }
  };

  const paymentCreditConfig = async () => {
    // const sharedSecret = "j7|Cw$7fMB"; //TODO: Get this from backend individual for every store
    // const storeId = "12022224687"; //TODO: Get this from backend individual for every store

    const sharedSecret = "Zek-y;28HA"; //"j7|Cw$7fMB"; //TODO: Get this from backend individual for every store
    const storeId = "1238595801";

    const d = new Date();
    const datestring =
      d.getFullYear() +
      ":" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      ":" +
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + d.getHours()).slice(-2) +
      ":" +
      ("0" + d.getMinutes()).slice(-2) +
      ":" +
      ("0" + d.getSeconds()).slice(-2);

    const separator = "|";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const txntype = "sale";
    const checkoutoption = "combinedpage";
    const paymentMethod = "M";
    const chargetotal = Math.round(paymentInfo.paymentAmount)
      // .toFixed(2)
      .toString();
    const currency = "978";

    const stringToHash =
      chargetotal +
      separator +
      checkoutoption +
      separator +
      currency +
      separator +
      "HMACSHA256" +
      separator +
      paymentMethod +
      separator +
      storeId +
      separator +
      timezone +
      separator +
      datestring +
      separator +
      txntype;

    const hashHMACSHA256 = hmacSha256(stringToHash, sharedSecret);
    const extendedhash = encBase64.stringify(hashHMACSHA256);

    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", "https://www.ipg-online.com/connect/gateway/processing");

    const i1 = document.createElement("input");
    i1.setAttribute("type", "hidden");
    i1.setAttribute("name", "txntype");
    i1.setAttribute("value", txntype);
    form.appendChild(i1);

    const i2 = document.createElement("input");
    i2.setAttribute("type", "hidden");
    i2.setAttribute("name", "timezone");
    i2.setAttribute("value", timezone);
    form.appendChild(i2);

    const i3 = document.createElement("input");
    i3.setAttribute("type", "hidden");
    i3.setAttribute("name", "txndatetime");
    i3.setAttribute("value", datestring);
    form.appendChild(i3);

    const i4 = document.createElement("input");
    i4.setAttribute("type", "hidden");
    i4.setAttribute("name", "hash_algorithm");
    i4.setAttribute("value", "HMACSHA256");
    form.appendChild(i4);

    const i5 = document.createElement("input");
    i5.setAttribute("type", "hidden");
    i5.setAttribute("name", "hashExtended");
    i5.setAttribute("value", extendedhash);
    form.appendChild(i5);

    const i6 = document.createElement("input");
    i6.setAttribute("type", "hidden");
    i6.setAttribute("name", "storename");
    i6.setAttribute("value", storeId);
    form.appendChild(i6);

    const i7 = document.createElement("input");
    i7.setAttribute("type", "hidden");
    i7.setAttribute("name", "checkoutoption");
    i7.setAttribute("value", checkoutoption);
    form.appendChild(i7);

    const i8 = document.createElement("input");
    i8.setAttribute("type", "hidden");
    i8.setAttribute("name", "paymentMethod");
    i8.setAttribute("value", paymentMethod);
    form.appendChild(i8);

    const i9 = document.createElement("input");
    i9.setAttribute("type", "hidden");
    i9.setAttribute("name", "chargetotal");
    i9.setAttribute("value", chargetotal);
    form.appendChild(i9);

    const i10 = document.createElement("input");
    i10.setAttribute("type", "hidden");
    i10.setAttribute("name", "currency");
    i10.setAttribute("value", currency);
    form.appendChild(i10);

    var s = document.createElement("input");
    s.setAttribute("type", "hidden");
    s.setAttribute("type", "submit");
    s.setAttribute("value", "Submit");
    form.appendChild(s);

    document.getElementsByTagName("body")[0].appendChild(form);

    s.click();

    document.getElementsByTagName("body")[0].removeChild(form);
  };

  return (
    <>
      <div className="closeBtnDivide" data-bs-dismiss="modal" onClick={() => props.handleClose()}>
        <i className="fas fa-times"></i>
      </div>
      <div className="otheroptionSection">
        <div className="divideCalc2">
          <h1 className="totalPr2" style={{ textAlign: "center" }}>
            WAY TO PAY
          </h1>
          <div className="payWrap">
            <span
              className="spSpan"
              data-bs-dismiss="modal"
              onClick={() => {
                props.setShowCredit(false);
                props.setShowFinal(true);
              }}
            >
              <img src="https://app1.va-piano.ch/img/cashier_white.fw.png" alt="cash" />
              <p>At Cashier</p>
            </span>
            {loadingStatus ? (
              <span
                className="spinner-border spinner-border-lg text-danger text-center"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <span
                className="spSpan2"
                data-bs-dismiss="modal"
                onClick={() => {
                  handlePayment();
                }}
              >
                <img src="./img/credit_card_white.png" alt="credit card" />
                <p>Credit Card</p>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
    // <div className="text-center">
    //   <h1>WAY TO PAY</h1>
    //   <Row className="justify-content-between">
    //     <Col sm={5}>
    //       <Button size="lg" variant="info" className="text-white shadow-lg " style={{ width: '170px', height: '75px' }} onClick={() => { props.setShowCredit(false); props.setShowFinal(true) }}>
    //         <img src="https://app1.va-piano.ch/img/cashier_white.fw.png" alt="cashier" width={35} height={30} className=" text-center" />
    //         <i>At Cashier</i>
    //       </Button>
    //     </Col>
    //     <Col sm={5}>
    //       <Button size="lg" variant="info" className="text-white shadow-lg" style={{ width: '170px', height: '75px' }} onClick={() => { props.setShowCredit(false); props.setShowCreditCard(true) }}>
    //         <i className="fas fa-credit-card d-block"></i>
    //         <i>Credit Card</i>
    //       </Button>
    //     </Col>
    //   </Row>

    // </div>
  );
};

export default CreditModal;
