import { v4 as uuidv4 } from "uuid";
import { socket } from "../../services/socket.services";
// const tableInfo = useSelector((state) => state.table);
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  UPDATE_CART,
  INCREASE_QUANTITY,
  DELETE_FROM_CART,
  POST_ORDER,
  GET_COURSES,
  ADD_INGREDIENTS,
  DELETE_INGREDIENTS,
  GET_CART_LENGTH,
  UPDATE_CART_COURSE,
  SYNC_CARTS,
  GET_ORDERS_BY_SESSION,
  SET_LOADING,
  ORDER_PAYMENT,
  SET_ORDER_PAYMENT,
  SET_PAYMENT_STATUS,
  EMPTY_ORDERS_PLACED,
  SET_PLACED_LOADING,
  SET_ORDER_SUCCESS,
  GET_ORDERS_BY_FIREBASE
} from "../types";

const initialState = {
  numberOfOrdersInCart: 0,
  courses: [],
  ordersPlaced: [],
  orderPaymentInfo: [],
  Carts: [],
  tempCart: [],
  loading: true,
  paymentStatus: false,
  orderPlacedLoading: false,
  orderSuccess: false
};
function UUIdToNumber(string) {
  var numsStr = string.match(/\d/g);
  var joined = numsStr.join("");
  return Number(joined);
}
export default function orderReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "EMTY_TEMP_CART":
      return {
        ...state,
        tempCart: []
      };
    case SYNC_CARTS: {
      if (Object.prototype.hasOwnProperty.call(payload, "remove")) {
        state.tempCart.forEach((tCart) => {
          tCart.subItems.forEach((ing_gid) => {
            if (ing_gid?.group_id === payload?.group_id_ing) {
              var indexOfIng = tCart.subItems.indexOf(ing_gid?.group_id);
              tCart.subItems.splice(indexOfIng, 1);
            }
          });
        });
        return {
          ...state
        };
      } else {
        state.tempCart = [];

        let ingredientQty = payload?.ingredients?.map((ingX) => {
          return {
            ...ingX,
            quantity: 1,
            course: payload.course
          };
        });

        let _cart = {
          id: Number(payload.id),
          quantity: 1,
          name: payload?.name || payload?.name,
          group: payload?.group,
          image: payload?.download_url || payload?.image,
          sku: payload?.sku || payload?.sku,
          course: payload?.course,
          price: payload?.price,
          prepTime: payload?.preperation_time,
          subItems: ingredientQty?.filter((ingFilt) => {
            return ingFilt?.selected === true;
          })
        };
        state.tempCart.push(_cart);

        return {
          ...state
        };
      }
    }
    case ADD_INGREDIENTS: {
      if (state.tempCart.length > 0) {
        state.tempCart[0].subItems = payload.subItemIng;
      }

      return {
        ...state
      };
    }

    case DELETE_INGREDIENTS: {
      let parent = state.tempCart.find((item) => item.sku === payload.sku);
      let parentIndex = state.tempCart.findIndex((item) => item.sku === payload.sku);
      state.tempCart[parentIndex].subItems = parent?.subItems?.filter(
        (item) => item.sku !== payload.subitemSku
      );
      return {
        ...state
      };
    }

    case ADD_TO_CART: {
      let uid = parseInt(UUIdToNumber(uuidv4()));
      uid = Math.trunc(uid * 100) / 100;

      let test = state.tempCart.some(
        (itt) => itt.sku === payload.item.sku && itt.name === payload.item.name
      );

      if (test) {
        let cart = {
          id: uid,
          group: payload.item.group,
          quantity: payload.item.quantity,
          prepTime: payload.item.prepTime,
          name: payload.item.name,
          image: payload.item.download_url || payload?.item.image,
          sku: payload.item.sku,
          course: payload.item.course,
          price: payload.item.price,
          subItems: payload.latestIng.filter((tr) => tr.selected === true),
          endTime: new Date(new Date().getTime() + 5 * 60000)
        };
        state.Carts.push(cart);
      }

      return {
        ...state,
        numberOfOrdersInCart: state.numberOfOrdersInCart + 1
      };
    }
    case UPDATE_CART: {
      return {
        ...state,
        orders: payload
      };
    }
    case DELETE_FROM_CART: {
      if (payload.temporary === true) {
        return {
          ...state,
          numberOfOrdersInCart: state.Carts.length,
          tempCart: state.tempCart.filter((item) => {
            return item.id !== Number(payload.tempCart.id);
          })
        };
      } else {
        return {
          ...state,
          numberOfOrdersInCart: state.Carts.length,
          Carts: state.Carts.filter((item) => {
            return item.id !== Number(payload.id);
          })
        };
      }
    }

    case GET_CART_LENGTH: {
      let numberCart = state.Carts.length;

      return {
        ...state,
        numberOfOrdersInCart: numberCart
      };
    }
    case INCREASE_QUANTITY: {
      let index = payload.temp;
      if (payload.temporary === true) {
        state.tempCart[index].quantity++;
      } else {
        state.Carts[payload].quantity++;
      }

      return {
        ...state
      };
    }
    case DECREASE_QUANTITY: {
      let index = payload.temp;

      if (payload.temporary === true) {
        let quantity = state.tempCart[index].quantity;
        if (quantity > 1) {
          state.tempCart[index].quantity--;
        }
      } else {
        let quantity = state.Carts[payload].quantity;
        if (quantity > 1) {
          state.Carts[payload].quantity--;
        }
      }

      return {
        ...state
      };
    }

    case UPDATE_CART_COURSE: {
      let index = state.Carts.findIndex((item) => {
        return item.id === payload.id;
      });
      state.Carts[index].course = payload.course;
      return {
        ...state
      };
    }

    case GET_COURSES: {
      return {
        ...state,
        courses: payload
      };
    }

    case POST_ORDER: {
      if (payload?.statusInfo.status === 200) {
        state.Carts = [];

        state.numberOfOrdersInCart = 0;
        socket.emit("tableCreated", { sessionId: payload?.payload?.sessionID });
      }

      return {
        ...state,
        orderSuccess: "pending",
        loading: false
      };
    }

    case SET_ORDER_SUCCESS: {
      return {
        orderSuccess: payload
      };
    }

    case GET_ORDERS_BY_SESSION: {
      return {
        ...state,
        ordersPlaced: payload?.data?.data,
        loading: false
      };
    }
    case GET_ORDERS_BY_FIREBASE: {
      let ordersItems = state.ordersPlaced;
      let newItems = [];
      if (ordersItems?.length) {
        payload.salesEntries?.forEach((_item) => {
          if (!ordersItems[0]?.salesEntries?.find((_itm) => _itm._id === _item._id)) {
            newItems.push(_item);
          }
        });
        console.log(newItems);
        ordersItems[0].salesEntries = [...ordersItems[0].salesEntries, ...newItems];
      } else {
        ordersItems.push(payload);
      }

      // if()
      // // eslint-disable-next-line no-unsafe-optional-chaining
      // ordersItems[0].salesEntries = [...ordersItems.salesEntries, ...newItems];
      return {
        ...state
        // ordersPlaced: ordersItems
      };
    }
    case EMPTY_ORDERS_PLACED: {
      return {
        ...state,
        ordersPlaced: []
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: payload
      };
    }
    case SET_PLACED_LOADING: {
      return {
        ...state,
        orderPlacedLoading: payload
      };
    }
    case SET_PAYMENT_STATUS: {
      return {
        ...state,
        paymentStatus: payload
      };
    }

    case ORDER_PAYMENT: {
      state.ordersPlaced = [];

      return {
        ...state,
        ordersPlaced: []
      };
    }

    case SET_ORDER_PAYMENT: {
      return {
        ...state,
        orderPaymentInfo: payload
      };
    }

    default:
      return state;
  }
}
