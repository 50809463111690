//import firebase from "firebase";
import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyCAB01rdL-kbUtDbw_rz3kcyFb9PE3rTrg",
  authDomain: "operate-2ca18.firebaseapp.com",
  projectId: "operate-2ca18",
  storageBucket: "operate-2ca18.appspot.com",
  messagingSenderId: "193253639996",
  appId: "1:193253639996:web:ab5001f9c99f147be9c039"
};
export const firebase = app.initializeApp(firebaseConfig);
export const auth = app.auth();
export const db = app.database();
export const firestore = app.firestore();
// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

//export default firebase;
